<template>
  <div class="trad">
    <div class="head" >
      查询到<span class="res">{{total}}</span>个相关商标
    </div>
    <el-skeleton :rows="26" animated v-show="state02" class="skeleton"/>
      <Box :data="data" @chata="chata" @sigin="sigin"/>
    <el-pagination background 
    layout="prev, pager, next"
    @prev-click="prevClick"
    @next-click="nextClick"
    @current-change="currentChange"
    :current-page="newfrom"
    :page-size="page"
    :total="total">
    </el-pagination>
    <!--选中页事件 @current-change="currentChange" -->
    <!-- total=总条目数
    page-size=每页显示的条目
    current-page=当前页数，支持 v-model 双向绑定
    prev-click=用户点击上一页按钮改变当前页时触发
    next-click=用户点击下一页按钮改变当前页时触发 -->

    <!-- 限制查阅登录弹窗 -->
  <div class="logn" v-show="signin">
    <p class="title">
      <span >//</span>
      <span >登录问标网，查看更多信息</span>
      <span >//</span>
    </p>
    <button class="sign" @click="sign">立即登录</button>
  </div>
  </div>
</template>

<script>
 import Box from './box.vue' 
 
export default {
  props:["data","siginState","allPrev","size","loginState","from","state01"],
  components:{
    Box,
  },
  data(){
    return {
      signin:this.loginState,
      block:this.siginState,
      page:this.size,
      total:0,
      state:true,
      newfrom:this.from,
      state02:this.state01,
    }
  },
  created(){
  },
  watch:{
    allPrev(curVal,oldVal){
      if(curVal!= 0){
        this.total = curVal
      } 
    },
    siginState(curVal,oldVal){
      if(curVal!== 0){
        this.block = curVal
      } 
    },
    loginState(curVal,oldVal){
      if(curVal!== 0){
        this.signin = curVal
      } 
    },
    from(curVal,oldVal){
      if(curVal!= -1){
        this.newfrom = curVal
      } 
    },
    size(curVal,oldVal){
      if(curVal!==0){
        this.page = curVal
      } 
    },
    state01(curVal,oldVal){
      if(curVal!==""){
        this.state02 = curVal
      } 
    },

  },
  methods:{
    sigin(data) {
      this.$emit("sigin", data);
    },
    sign(){
      this.block = true
      this.$emit("sigin",this.block)
    },
    prevClick(data){
      this.$emit('prevClick',data)
    },
    nextClick(data){
      this.$emit('nextClick',data)
    },
    currentChange(data){
      this.$emit('currentChange',data)
    },
    chata(data){
      this.$emit("chata",data)
    },
  }
}
</script>

<style scoped lang="scss">
.trad {
  width: 74.375rem /* 1190px -> 74.375rem */;
  margin-top: 1.75rem /* 28px -> 1.75rem */;
  background-color: #fff;
  box-shadow:  0 0 0.625rem /* 10px -> 0.625rem */  0.3125rem #e9e8e8;
  border-bottom:0.0625rem /* 1px -> 0.0625rem */ solid #EFEFEF;
  margin-bottom: 5.875rem /* 94px -> 5.875rem */;
  padding-bottom: 3.125rem /* 50px -> 3.125rem */;
  position: relative;
  .skeleton{
    position: relative;
    left: 1.25rem /* 20px -> 1.25rem */;
    top: 1.25rem /* 20px -> 1.25rem */;
    width: 62.5rem /* 1000px -> 62.5rem */;
  }
  .head {
    width: 100%;
    height: 3.4375rem /* 55px -> 3.4375rem */;
    font-size: 0.875rem /* 14px -> 0.875rem */;
    padding: 1.0625rem /* 17px -> 1.0625rem */;
    box-sizing: border-box;
    line-height: 3.4375rem /* 55px -> 3.4375rem */;
    .res {
      color: #4D89F1;
    }
  }
  .el-pagination{
      margin-top: 3.125rem /* 50px -> 3.125rem */;
  }
  .logn{
      position: absolute;
      bottom: 0;
      height: 25.1875rem /* 403px -> 25.1875rem */;
      width: 100%;
      overflow: hidden;
      background: linear-gradient(180deg, rgba(77, 137, 241, 0.6), rgba(255, 255, 255, 0));
      backdrop-filter: blur(4px) brightness(100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        width: 22.5rem /* 360px -> 22.5rem */;
        font-size: 1.375rem /* 22px -> 1.375rem */;
        color: #4D89F1;
        justify-content: center;
        display: flex;
        justify-content: space-between;
        margin-top:1.3125rem /* 21px -> 1.3125rem */ ;
      }
      .sign {
        width: 10.5625rem /* 169px -> 10.5625rem */;
        height: 2.375rem /* 38px -> 2.375rem */;
        margin-top: 5.625rem /* 90px -> 5.625rem */;
        border-radius: 1.1875rem /* 19px -> 1.1875rem */;
        font-size: 1.125rem /* 18px -> 1.125rem */;
        color: #fff;
        background-color: #4D89F1;
        text-align: center;
        line-height: 2.375rem /* 38px -> 2.375rem */;
        border: none;
      }
  }
    
}
</style>