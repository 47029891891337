<template>
  <div
    class="result"
    :style="{ height: come02state ? 'auto' : '6.25rem' }"
    tabIndex="1"
    ref="down"
  >
    <!-- 查找范围 -->
    <div class="classificationList">
      <!-- 表标题 -->
      <div class="listTitle">查找范围</div>
      <div class="listContent">
        <div class="option" v-for="(item, index) in Range" :key="index">
          <img
            src="../../imger/tranClassGroup/cose.png"
            alt="问标网"
            class="listClose"
            @click="close1(index)"
          />
          <div
            class="unSelected"
            :class="fn1Color == index ? 'true' : ''"
            @click="fn1(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>

    <!-- 商标形式 -->
    <div class="classificationList">
      <!-- 表标题 -->
      <div class="listTitle">商标形式</div>
      <!-- 分类列表 -->
      <div class="listContent">
        <div class="option" v-for="(item, index) in form" :key="index">
          <img
            src="../../imger/tranClassGroup/cose.png"
            alt="问标网"
            class="listClose"
            @click="close2(index)"
          />
          <div
            class="unSelected"
            :class="fn2Color == index ? 'true' : ''"
            @click="fn2(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <a
        class="put"
        @click="put"
        tabindex="1"
        :style="{ color: come02state == true ? '#4D89F1' : '' }"
      >
        {{ come02state == true ? "收起" : "展开更多" }}
        <img
          src="../../imger/put.png"
          :class="come02state == true ? 'come02Copy' : 'come02'"
          alt="问标网"
      /></a>
    </div>

    <!-- 商标状态 -->
    <div class="classificationList">
      <!-- 表标题 -->
      <div class="listTitle">商标状态</div>
      <!-- 分类列表 -->
      <div class="listContent">
        <div class="option" v-for="(item, index) in state" :key="index">
          <img
            src="../../imger/tranClassGroup/cose.png"
            alt="问标网"
            class="listClose"
            @click="close3(index)"
          />
          <div
            class="unSelected"
            :class="fn3Color == index ? 'true' : ''"
            @click="fn3(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>

    <!-- 商标分类列表 -->
    <div class="classificationList">
      <!-- 表标题 -->
      <div class="listTitle">商标类别</div>
      <!-- 分类列表 -->
      <div class="listContent">
        <div
          class="option"
          v-for="(item, index) in classificationList"
          :key="index"
        >
          <img
            src="../../imger/tranClassGroup/cose.png"
            alt="问标网"
            class="listClose"
            @click="close4(index)"
            :style="{ display: item.cate ? 'block' : 'none' }"
          />

          <div
            class="unSelected"
            :style="{ border: item.cate ? '0.1875rem solid #4D89F1' : '' }"
            @click="fn4(index)"
          >
            {{ item.code }}-{{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <!-- 申请时间 -->
    <div class="classificationList">
      <!-- 表标题 -->
      <div class="listTitle">申请时间</div>
      <!-- 分类列表 -->
      <div class="listContent">
        <div class="option" v-for="(item, index) in time" :key="index">
          <img
            src="../../imger/tranClassGroup/cose.png"
            alt="问标网"
            class="listClose"
            @click="close5(index)"
          />
          <div
            class="unSelected"
            :class="fn5Color == index ? 'true' : ''"
            @click="fn5(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["Classdata", "year", "fnColor", "mor", "sta", "cate", "conyear"],
  data() {
    return {
      Range: ["智能查询", "精准名称", "申请人", "申请/注册号"],
      form: ["所有形式", "中文", "英文", "其他"],
      state: ["所有状态", "已注册", "过期", "等待审查", "无效", "其他"],
      classificationList: "",
      time: this.year,
      beforeIndex: "", //
      fn1Color: 0,
      fn2Color: 0,
      fn3Color: 0,
      fn5Color: 0,
      come02state: false,
    };
  },
  created() {
    // 解密url后的查询方式赋值
    if (this.fnColor == "smark") {
      this.fn1Color = 0;
    }
    if (this.fnColor == "precise") {
      this.fn1Color = 1;
    }
    if (this.fnColor == "proposer") {
      this.fn1Color = 2;
    }
    if (this.fnColor == "") {
      this.fn1Color = 3;
    }
    // 解密url后的商标形式赋值
    if (this.mor == "") {
      this.fn2Color = 0;
    }
    if (this.mor == "中文") {
      this.fn2Color = 1;
    }
    if (this.mor == "英文") {
      this.fn2Color = 2;
    }
    if (this.mor == "其他") {
      this.fn2Color = 3;
    }

    // 解密url后的商标状态赋值
    var res = this.state.findIndex((el) => el == this.sta);
    if (res !== -1) {
      this.fn3Color = res;
    } else {
      this.fn3Color = 0;
    }

    // 以上的完成之后当商标状态、大类、年份有选上，展开筛选表
    if (this.fn3Color !== 0) {
      this.come02state = true;
    }
  },
  watch: {
    Classdata(newdata, oldarr) {
      if (newdata) {
        this.classificationList = newdata;
      }
      if (this.classificationList.findIndex((el) => el.cate == true) !== -1) {
        this.come02state = true;
      }
    },
    // 查询到的商标时间的数据
    year(newdata, oldarr) {
      if (newdata !== 0) {
        this.time = newdata;
      }
      // 解密url后的申请时间赋值
      let time = this.time.findIndex((el) => el == this.conyear);
      if (time !== -1) {
        this.fn5Color = time;
        this.come02state = true;
      } else {
        this.fn5Color = 0;
      }
    },
  },
  methods: {
    async fn1(index) {
      if (sessionStorage.getItem("userkey") == null) {
        this.$message({
          message: "请先登录，更好的能为您服务！",
          type: "warning",
        });
        this.$emit("sigin", true);
      } else {
        // this.fn1Color = index;
        this.$emit("searchRange", index);
        await sessionStorage.setItem("findState", index);
      }
      this.fn2Color = -1;
      this.fn3Color = -1;
      this.fn5Color = -1;
    },
    fn2(index) {
      if (sessionStorage.getItem("userkey") == null) {
        this.$message({
          message: "请先登录，更好的能为您服务！",
          type: "warning",
        });
        this.$emit("sigin", true);
      } else {
        this.fn2Color = index;
        this.$emit("search1", this.form[index]);
      }
    },
    fn3(index) {
      if (sessionStorage.getItem("userkey") == null) {
        this.$message({
          message: "请先登录，更好的能为您服务！",
          type: "warning",
        });
        this.$emit("sigin", true);
      } else {
        this.fn3Color = index;
        this.stateindex = index;
        this.$emit("search2", this.state[index]);
      }
    },
    fn4(index) {
      if (sessionStorage.getItem("userkey") == null) {
        this.$message({
          message: "请先登录，更好的能为您服务！",
          type: "warning",
        });
        this.$emit("sigin", true);
      } else {
        this.$emit("search3", this.classificationList[index].code);
      }
    },
    close4(index) {
      this.$emit("search3Close", this.classificationList[index].code);
    },
    fn5(index) {
      if (sessionStorage.getItem("userkey") == null) {
        this.$message({
          message: "请先登录，更好的能为您服务！",
          type: "warning",
        });
        this.$emit("sigin", true);
      } else {
        this.fn5Color = index;
        this.timeindex = index;
        this.$emit("search4", this.time[index]);
      }
    },
    put() {
      if (this.come02state == true) {
        this.come02state = false;
      } else {
        this.come02state = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.true {
  color: #4d89f1 !important;
  font-size: 0.9375rem /* 15px -> 0.9375rem */ !important;
  font-weight: bold;
}
.false {
  color: #666666;
}
// .n-collapse .n-collapse-item .n-collapse-item__header{
//   font-size: 1.5625rem /* 25px -> 1.5625rem */ !important;
// }

.result {
  height: 6.25rem /* 100px -> 6.25rem */;
  margin-top: 1.4375rem /* 23px -> 1.4375rem */;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 0.625rem /* 10px -> 0.625rem */ 0.3125rem #e9e8e8;
  overflow: hidden;
  .classificationList {
    width: 74.375rem /* 1190px -> 74.375rem */;
    // padding-top: 3rem /* 48px -> 3rem */;
    // padding-bottom: 3rem /* 48px -> 3rem */;
    box-sizing: border-box;
    display: flex;
    .listTitle {
      color: #666666;
      font-size: 0.875rem /* 14px -> 0.875rem */;
      margin-left: 1.25rem /* 20px -> 1.25rem */;
      margin-top: 1.125rem /* 18px -> 1.125rem */;
      margin-right: 1.875rem /* 30px -> 1.875rem */;
    }
    .listContent {
      width: 0;
      flex-grow: 1;

      .option {
        display: inline-block;
        width: 5.625rem /* 90px -> 5.625rem */;
        height: 2.5rem /* 40px -> 2.5rem */;
        margin: 0.1875rem /* 3px -> 0.1875rem */;
        position: relative;
        cursor: pointer;
        .unSelected {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 1.9375rem /* 31px -> 1.9375rem */;
          border: 0.1875rem /* 3px -> 0.1875rem */ solid #fff;
          box-sizing: border-box;
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #666666;
          text-align: center;
          line-height: 1.5625rem; /* 25px -> 1.5625rem */
        }
        .listClose {
          display: none;
          width: 0.6875rem /* 11px -> 0.6875rem */;
          height: 0.5625rem /* 9px -> 0.5625rem */;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
    .put {
      color: #666666;
      font-size: 0.875rem /* 14px -> 0.875rem */;
      display: flex;
      align-items: center;
      width: 6.25rem /* 100px -> 6.25rem */;
      cursor: pointer;
      .come02 {
        transition-duration: 0.5s;
        transform: rotate(180deg);
      }
      .come02Copy {
        transition-duration: 0.5s;
        transform: rotate(90deg);
      }
    }
    .put:hover {
      color: #4d89f1;
    }
  }
}
// .result:hover{
//   box-shadow:  0 0 0.625rem /* 10px -> 0.625rem */  0.3125rem #4D89F1;
// }
</style>