<template>
  <div class="trademarkQueryResults">
    <!-- 商标查询结果页 -->

    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch :tradName="tradName" @newtradName="textsousuo" />

    <!-- 商标查询分类内容 -->
    <div class="index">
      <div class="conten">
        <Result
          :Classdata="classificationList"
          :year="yearData"
          :fnColor="search_type"
          :mor="mor"
          :sta="condition.State"
          :cate="condition.Category"
          :conyear="condition.Year"
          @search1="search1"
          @search2="search2"
          @search3="search3"
          @search3Close="search3Close"
          @search4="search4"
          @sigin="sigin"
          @searchRange="searchRange"
        />
      </div>

      <div class="conten">
        <Trademark
          :siginState="siginState"
          :Block="Block"
          :data="tradData"
          :allPrev="total"
          :size="size"
          :loginState="loginState"
          :from="from"
          @sigin="sigin"
          @nextClick="nextClick"
          @prevClick="prevClick"
          @currentChange="currentChange"
          @chata="chata"
          :state01="state01"
        />
        <!-- @currentChange="currentChange" -->
      </div>
    </div>
    <!-- 登录弹窗 -->
    <Signin :loginState="siginState" @close="close" />

    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
// 下一页 上一页 选中当前页 以及搜他的加密处理还未成功

import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";
import Result from "../components/trademarkQueryResults/result.vue";
import Trademark from "../components/trademarkQueryResults/trad.vue";
import Signin from "../components/trademarkQueryResults/signin.vue";

// 加密js文件引入
import { encryptDes, decryptDes, generatekey } from "@/config/DES";
import {
  intelligentSearch,
  keyCode,
  newIP,
  tranClassApi,
  preciseSearch,
  applicantSearch,
  registerSearch,
  conditionSearch,
} from "../API/index";

export default {
  inject: ["reload"],
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
    Result,
    Trademark,
    Signin,
  },
  data() {
    return {
      ip: "",
      siginState: false,
      tradName: "",
      tradData: [],
      Block: true,
      count: "", //商标查询请求返回的总条目数
      from: 1, //查询结果的页码
      size: 50, //查询结果的一页条目数
      total: 0, //查询结果的总条目数
      classificationList: [],
      yearData: [],
      search: "", //查询方式的索引，用于选择查询方式
      userData: "",
      userkey: "",
      condition: {
        //条件搜索对应的条件
        Morphology: "",
        Category: [],
        State: "",
        Year: "",
      },
      search_type: "smark",
      loginState: "",
      fn1Color: 0,
      userID: "",
      state01: false, //骨架屏显示
      dess: "", //解密后赋值的参数
      mor: "",
    };
  },
  async beforeCreate() {
    // 判断是否第一次进入页面
    if (sessionStorage.getItem("key") == null) {
      let ip;
      const resIP = await newIP();
      if (resIP.msg == "success") {
        ip = resIP.data;
      }
      const res = await keyCode({
        // 获取客户端的ip地址
        ip: ip,
        platform: "web",
      });
      await sessionStorage.setItem("key", res.data);
    }
    // 解密判断当前搜索是否需要用到userkey
    var keys = generatekey();
    const index = window.location.pathname.indexOf(".html");
    const idpass = window.location.pathname.slice(8, index);
    const dess = JSON.parse(decryptDes(idpass, keys));
    if (
      dess.type !== "smark" ||
      dess.page !== 1 ||
      dess.Mor !== undefined ||
      dess.State !== undefined ||
      dess.Cate !== undefined ||
      dess.Year !== undefined
    ) {
      if(sessionStorage.getItem('userkey')==null && localStorage.getItem('user')==null){
        this.$router.push({ path: "/login" });
      }
      
    }
    
  },
  async created() {
    // 解密url对其筛选分类的值赋值
    // 解密url
    sessionStorage.removeItem("room_id");
    var keys = generatekey();
    const index = window.location.pathname.indexOf(".html");
    const idpass = window.location.pathname.slice(8, index);
    this.dess = JSON.parse(decryptDes(idpass, keys));

    // 接收解密的值
    this.tradName = this.dess.tradName;
    this.search_type = this.dess.type;
    this.from = this.dess.page;
    // 一页数量
    if (this.dess.size !== undefined) {
      this.size = this.dess.size;
    }
    // 商标形式
    if (this.dess.Mor !== undefined) {
      // 商标形式暂未开通先为”4
      this.mor = this.dess.Mor;
      if (
        this.dess.Mor == "中文" ||
        this.dess.Mor == "英文" ||
        this.dess.Mor == "其他"
      ) {
        this.condition.Morphology = "4";
      } else {
        this.condition.Morphology = "";
      }
    }
    // 商标状态
    if (this.dess.State !== undefined) {
      this.condition.State = this.dess.State;
    }
    // 商标大类
    if (this.dess.Cate !== undefined) {
      this.condition.Category = this.dess.Cate;
    }
    // 申请时间
    if (this.dess.Year !== undefined) {
      this.condition.Year = this.dess.Year;
    }

    await this.keyCode();
    this.userID = JSON.parse(localStorage.getItem("user"));
    this.userkey = sessionStorage.getItem("userkey");
    this.state01 = true;
    await this.creat();

    switch (this.search_type) {
      case "smark":
        if (
          this.condition.Morphology == "" &&
          JSON.stringify(this.condition.Category) == "[]" &&
          this.condition.State == "" &&
          this.condition.Year == ""
        ) {
          await this.fn1(this.tradName);
        } else {
          await this.conditionSearch();
        }
        break;
      case "precise":
        if (
          this.condition.Morphology == "" &&
          JSON.stringify(this.condition.Category) == "[]" &&
          this.condition.State == "" &&
          this.condition.Year == ""
        ) {
          await this.fn2(this.tradName);
        } else {
          await this.conditionSearch();
        }
        break;
      case "proposer":
        if (
          this.condition.Morphology == "" &&
          JSON.stringify(this.condition.Category) == "[]" &&
          this.condition.State == "" &&
          this.condition.Year == ""
        ) {
          await this.fn3(this.tradName);
        } else {
          await this.conditionSearch();
        }
        break;
      case "":
        if (
          this.condition.Morphology == "" &&
          JSON.stringify(this.condition.Category) == "[]" &&
          this.condition.State == "" &&
          this.condition.Year == ""
        ) {
          await this.fn4(this.tradName);
        } else {
          await this.conditionSearch();
        }
        break;
      default:
        break;
    }
  },
  methods: {
    // 获取key
    async keyCode() {
      const resIP = await newIP();
      if (resIP.msg == "success") {
        this.ip = resIP.data;
      }
      let duan = sessionStorage.getItem("key");
      if (duan == null) {
        const res = await keyCode({
          // 获取客户端的ip地址
          ip: this.ip,
          platform: "web",
        });
        await sessionStorage.setItem("key", res.data);
      } else {
      }
    },
    // 查询大类请求
    async creat() {
      let key = sessionStorage.getItem("key");
      const res = await tranClassApi({
        key: key,
      });

      if (res.data.key) {
        res.data.category.forEach((el) => (el.cate = false));
        this.condition.Category.forEach((el) => {
          res.data.category.forEach((rl) => {
            if (parseInt(rl.code) == parseInt(el)) {
              rl.cate = true;
            }
          });
        });
        this.classificationList = res.data.category;
        await sessionStorage.setItem("key", res.data.key);
      }
    },
    // 选中页
    currentChange(data) {
      this.from = data;
      if (data * this.size > this.total) {
        this.size = this.total - (data - 1) * this.size;
      } else {
        this.size = 50;
      }
      // 加密对象
      let obj = {
        tradName: this.tradName,
        type: this.search_type,
        page: this.from,
        size: this.size,
      };
      // 筛选有选到的选项再添加到加密对象里
      if (this.mor !== "") {
        obj.Mor = this.mor;
      }
      if ((JSON.stringify(this.condition.Category) == "[]") == false) {
        obj.Cate = this.condition.Category;
      }
      if (this.condition.State !== "") {
        obj.State = this.condition.State;
      }
      if (this.condition.Year !== "") {
        obj.Year = this.condition.Year;
      }
      // 加密请求
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify(obj), keys);
      this.$router.push({ path: `/rearch/${sign}.html` });
      this.reload();
      this.backTop();
    },
    // 下一页
    nextClick(data) {
      this.from = data;
    },
    // 上一页
    prevClick(data) {
      this.from = data;
    },
    backTop() {
      // 周期计算当前页面滚动条的位置
      let top =
        document.body.scrollTop || //谷歌和苹果的safari都支持的获取当前滚动条纵坐标的位置
        document.documentElement.scrollTop; //IE6以上和火狐支持的获取当前滚动条纵坐标的位置
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 1);
    },
    sigin(data) {
      this.siginState = data;
    },
    close(data) {
      this.siginState = data;
    },
    // 查询筛选
    async search1(index) {
      if (this.condition.Morphology.includes(index) == false) {
        this.condition.Morphology = index;
      }
      // 加密对象
      let obj = {
        tradName: this.tradName,
        type: this.search_type,
        page: this.from,
      };
      // 筛选有选到的选项再添加到加密对象里
      if (this.condition.Morphology !== "") {
        obj.Mor = this.condition.Morphology;
      }
      if ((JSON.stringify(this.condition.Category) == "[]") == false) {
        obj.Cate = this.condition.Category;
      }
      if (this.condition.State !== "") {
        obj.State = this.condition.State;
      }
      if (this.condition.Year !== "") {
        obj.Year = this.condition.Year;
      }
      // 加密请求
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify(obj), keys);
      this.$router.push({ path: `/rearch/${sign}.html` });
      this.reload();
    },
    async search2(index) {
      if (index == "所有状态") {
        this.condition.State = "";
      } else {
        if (this.condition.State.includes(index) == false) {
          this.condition.State = index;
        }
      }
      // 加密对象
      let obj = {
        tradName: this.tradName,
        type: this.search_type,
        page: this.from,
      };
      // 筛选有选到的选项再添加到加密对象里
      if (this.mor !== "") {
        obj.Mor = this.mor;
      }
      if ((JSON.stringify(this.condition.Category) == "[]") == false) {
        obj.Cate = this.condition.Category;
      }
      if (this.condition.State !== "") {
        obj.State = this.condition.State;
      }
      if (this.condition.Year !== "") {
        obj.Year = this.condition.Year;
      }

      // 加密请求
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify(obj), keys);
      this.$router.push({ path: `/rearch/${sign}.html` });
      this.reload();
    },
    async search3(index) {
      if (this.condition.Category.includes(String(index)) == false) {
        this.condition.Category.push(String(index));
      }
      // 加密对象
      let obj = {
        tradName: this.tradName,
        type: this.search_type,
        page: this.from,
      };
      // 筛选有选到的选项再添加到加密对象里
      if (this.mor !== "") {
        obj.Mor = this.mor;
      }
      if ((JSON.stringify(this.condition.Category) == "[]") == false) {
        obj.Cate = this.condition.Category;
      }
      if (this.condition.State !== "") {
        obj.State = this.condition.State;
      }
      if (this.condition.Year !== "") {
        obj.Year = this.condition.Year;
      }
      // 加密请求
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify(obj), keys);
      this.$router.push({ path: `/rearch/${sign}.html` });
      this.reload();
    },
    async search3Close(index) {
      this.condition.Category.splice(
        this.condition.Category.findIndex((el) => el == String(index)),
        1
      );
      // 加密对象
      let obj = {
        tradName: this.tradName,
        type: this.search_type,
        page: this.from,
      };
      // 筛选有选到的选项再添加到加密对象里
      if (this.mor !== "") {
        obj.Mor = this.mor;
      }
      if ((JSON.stringify(this.condition.Category) == "[]") == false) {
        obj.Cate = this.condition.Category;
      }
      if (this.condition.State !== "") {
        obj.State = this.condition.State;
      }
      if (this.condition.Year !== "") {
        obj.Year = this.condition.Year;
      }
      // 加密请求
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify(obj), keys);
      this.$router.push({ path: `/rearch/${sign}.html` });
      this.reload();
    },
    async search4(index) {
      if (index == "所有年份") {
        this.condition.Year = "";
      } else {
        if (this.condition.Year.includes(index) == false) {
          this.condition.Year = index;
        }
      }
      // 加密对象
      let obj = {
        tradName: this.tradName,
        type: this.search_type,
        page: this.from,
      };
      // 筛选有选到的选项再添加到加密对象里
      if (this.mor !== "") {
        obj.Mor = this.mor;
      }
      if ((JSON.stringify(this.condition.Category) == "[]") == false) {
        obj.Cate = this.condition.Category;
      }
      if (this.condition.State !== "") {
        obj.State = this.condition.State;
      }
      if (this.condition.Year !== "") {
        obj.Year = this.condition.Year;
      }
      // 加密请求
      var keys = generatekey();
      var sign = encryptDes(JSON.stringify(obj), keys);
      this.$router.push({ path: `/rearch/${sign}.html` });
      this.reload();
    },
    // 搜索
    textsousuo(data) {
      this.tradName = data;
      switch (this.search_type) {
        case "smark":
          // 加密请求
          var keys = generatekey();
          var sign = encryptDes(
            JSON.stringify({
              tradName: this.tradName,
              type: "smark",
              page: this.from,
            }),
            keys
          );
          this.$router.push({ path: `/rearch/${sign}.html` });
          this.reload();
          break;
        case "precise":
          // 加密请求
          var keys = generatekey();
          var sign = encryptDes(
            JSON.stringify({
              tradName: this.tradName,
              type: "precise",
              page: this.from,
            }),
            keys
          );
          this.$router.push({ path: `/rearch/${sign}.html` });
          this.reload();
          break;
        case "proposer":
          // 加密请求
          var keys = generatekey();
          var sign = encryptDes(
            JSON.stringify({
              tradName: this.tradName,
              type: "proposer",
              page: this.from,
            }),
            keys
          );
          this.$router.push({ path: `/rearch/${sign}.html` });
          this.reload();
          break;
        case 3:
          // 加密请求
          var keys = generatekey();
          var sign = encryptDes(
            JSON.stringify({
              tradName: this.tradName,
              page: this.from,
            }),
            keys
          );
          this.$router.push({ path: `/rearch/${sign}.html` });
          this.reload();
          break;
        default:
          break;
      }
    },
    // 查询商标
    async fn1(data) {
      this.state01 = true;
      // if (this.from * this.size > this.total) {
      //   this.size = this.total - (this.from - 1) * this.size;
      // } else {
      //   this.size = 20;
      // }
      if (data == "") {
        this.$message({
          message: "请输入您要查询的商标！",
          type: "warning",
        });
      } else {
        this.userkey = sessionStorage.getItem("userkey");

        this.tradName = data;
        // axios请求根据名称查询商标
        const res = await intelligentSearch({
          key:
            this.userkey == null ? sessionStorage.getItem("key") : this.userkey,
          user_id: this.userID == null ? 0 : this.userID.id,
          phone: this.userID == null ? 0 : this.userID.phone,
          content: data,
          form: String((this.from - 1) * this.size),
          size: String(
            sessionStorage.getItem("userkey") == null ? 25 : this.size
          ),
        });
        if (res.data.key) {
          if (this.from - 1 != 0) {
            await sessionStorage.setItem("userkey", res.data.key);
          } else {
            await sessionStorage.setItem("key", res.data.key);
          }
          // 判断有无数据
          if (res.data.trademark == null) {
            this.total = "";
            this.tradData = [];
            this.yearData = [];
            this.yearData.unshift("所有年份");
          } else {
            this.total = res.data.count;
            this.tradData = res.data.trademark;
            this.yearData = res.data.year;
            this.yearData.unshift("所有年份");
          }
          // 根据登录到的userkey和查询到的条目数来决定登录遮罩层是否显现
          let signin = sessionStorage.getItem("userkey");
          if (signin == null && this.total >= 25) {
            this.loginState = true;
          } else {
            this.loginState = false;
          }
          this.state01 = false;
        }
      }
    },
    // 精准查询
    async fn2(data) {
      this.state01 = true;
      if (data == "") {
        this.$message({
          message: "请输入您要查询的商标！",
          type: "warning",
        });
      } else {
        const res = await preciseSearch({
          key: sessionStorage.getItem("userkey"),
          user_id: this.userID == null ? 0 : this.userID.id,
          content: data,
          page: this.from,
        });
        if (res.data.key) {
          await sessionStorage.setItem("userkey", res.data.key);
          if (res.data.trademarks == null) {
            this.total = "";
            this.tradData = [];
            this.yearData = [];
            this.yearData.unshift("所有年份");
          } else {
            this.total = res.data.search_auxiliary_resp.count;
            this.tradData = res.data.trademarks;
            this.yearData = res.data.search_auxiliary_resp.year;
            this.yearData.unshift("所有年份");
          }
          this.state01 = false;
        }
      }
    },
    // 申请人搜索
    async fn3(data) {
      this.state01 = true;
      if (data == "") {
        this.$message({
          message: "请输入您要查询的商标！",
          type: "warning",
        });
      } else {
        const res = await applicantSearch({
          key: sessionStorage.getItem("userkey"),
          user_id: this.userID == null ? 0 : this.userID.id,
          content: data,
          page: this.from,
        });
        if (res.data.key) {
          await sessionStorage.setItem("userkey", res.data.key);
          if (res.data.trademarks == null) {
            this.total = "";
            this.tradData = [];
            this.yearData = [];
          } else {
            this.total = res.data.search_auxiliary_resp.count;
            this.tradData = res.data.trademarks;
            this.yearData = res.data.search_auxiliary_resp.year;
            this.yearData.unshift("所有年份");
          }
          this.state01 = false;
        }
      }
    },
    // 注册号搜索
    async fn4(data) {
      this.state01 = true;
      if (data == "") {
        this.$message({
          message: "请输入您要查询的商标！",
          type: "warning",
        });
      } else {
        const res = await registerSearch({
          key: sessionStorage.getItem("userkey"),
          user_id: this.userID.id,
          reg_id: data,
          phone: this.userID.phone,
        });
        if (res.data.key) {
          await sessionStorage.setItem("userkey", res.data.key);
          if (res.data.trademarks == null) {
            this.total = "";
            this.tradData = [];
            this.yearData = [];
            this.yearData.unshift("所有年份");
          } else {
            this.total = res.data.trademarks.length;
            this.tradData = Array(res.data.trademarks);
            this.yearData = res.data.search_auxiliary_resp.year;
            this.yearData.unshift("所有年份");
          }
          this.state01 = false;
        }
      }
    },

    // 条件搜索
    async conditionSearch() {
      this.state01 = true;
      if (this.tradName == "") {
        this.$message({
          message: "请输入您要查询的商标！",
          type: "warning",
        });
      } else {
        const res = await conditionSearch({
          key: sessionStorage.getItem("userkey"),
          user_id: this.userID == null ? 0 : this.userID.id,
          content: this.tradName,
          phone: this.userID == null ? 0 : this.userID.phone,
          search_type: this.search_type,
          page: String(
            this.search_type == "smark" ? this.from * this.size : this.from
          ),
          condition: JSON.parse(JSON.stringify(this.condition)),
        });
        if (res.data.key) {
          await sessionStorage.setItem("userkey", res.data.key);
          if (res.data.trademarks == null) {
            this.total = "";
            this.tradData = [];
            this.yearData = [];
            this.yearData.unshift("所有年份");
          } else {
            this.total = res.data.search_auxiliary_resp.count;
            this.tradData = res.data.trademarks;
            this.yearData = res.data.search_auxiliary_resp.year;
            this.yearData.unshift("所有年份");
          }
          this.state01 = false;
        }
      }
    },

    // 查询范围切换
    searchRange(index) {
      switch (index) {
        case 0:
          // 加密请求
          var keys = generatekey();
          var sign = encryptDes(
            JSON.stringify({
              tradName: this.tradName,
              type: "smark",
              page: this.from,
            }),
            keys
          );
          this.$router.push({ path: `/rearch/${sign}.html` });
          this.reload();
          break;
        case 1:
          // 加密请求
          var keys = generatekey();
          var sign = encryptDes(
            JSON.stringify({
              tradName: this.tradName,
              type: "precise",
              page: this.from,
            }),
            keys
          );
          this.$router.push({ path: `/rearch/${sign}.html` });
          this.reload();
          break;
        case 2:
          // 加密请求
          var keys = generatekey();
          var sign = encryptDes(
            JSON.stringify({
              tradName: this.tradName,
              type: "proposer",
              page: this.from,
            }),
            keys
          );
          this.$router.push({ path: `/rearch/${sign}.html` });
          this.reload();
          break;
        case 3:
          // 加密请求
          var keys = generatekey();
          var sign = encryptDes(
            JSON.stringify({
              tradName: this.tradName,
              page: this.from,
            }),
            keys
          );
          this.$router.push({ path: `/rearch/${sign}.html` });
          this.reload();
          break;
        default:
          break;
      }
    },
    // 点击搜他进行申请人搜索
    chata(data) {
      // this.fn3(data);
      // 加密请求
      var keys = generatekey();
      var sign = encryptDes(
        JSON.stringify({
          tradName: data,
          type: "proposer",
          page: this.from,
        }),
        keys
      );
      this.$router.push({ path: `/rearch/${sign}.html` });
      this.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.trademarkQueryResults {
  position: relative;
}
.index {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  .content {
    width: 74.375rem /* 1190px -> 74.375rem */;
  }
}
</style>