<template>
  <div>
    <div class="box" v-for="(item, index) in trandata" :key="index">
      <div class="left" @click="fn1(index)">
        <div class="picture">
          <img class="imger" :alt="item.tii_name" :src="item.tii_image" />
        </div>
        <p>第{{ item.tii_internation_type }}类</p>
      </div>
      <div class="right">
        <div class="lineone" @click="fn1(index)">
          <p class="boxtitle">{{ item.tii_name }}</p>
          <StateDiv :word="item.tii_status" :none="none" />
        </div>
        <div class="linetow" @click="fn1(index)">
          <p class="word01">注册号/申请号：{{ item.tii_reg_id }}</p>
          <p class="word01">申请日期：{{ item.tii_register_date }}</p>
          <p class="word01">代理机构：{{ item.tii_agency }}</p>
        </div>
        <div class="linetow">
          <p class="word01">
            申请人：{{ item.tii_register_cn }}
            <a class="word01" @click="chata(item.tii_register_cn)">
              <img
                class="chata"
                :alt="item.tii_register_cn"
                src="../../imger/chata.png"
              />
              <a class="word02">搜Ta</a>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StateDiv from "../stateDiv.vue";
import sha1 from "sha1";
// 加密js文件引入
import { encryptDes, decryptDes, generatekey } from "@/config/DES";
export default {
  props: ["data"],
  components: {
    StateDiv,
  },
  data() {
    return {
      word1: {
        stateWord: "已注册",
        color: "#2DAA1D",
        none: "none",
      },
      word2: {
        stateWord: "已过期",
        color: "#F0000F",
        none: "none",
      },
      word3: {
        stateWord: "等待审核",
        color: "#EC7100",
        none: "none",
      },
      none: "none",
      trandata: this.data,
    };
  },
  watch: {
    data(newData, oldData) {
      if (newData !== 0) {
        this.trandata = newData;
      }
    },
  },
  created() {},
  methods: {
    fn1(data) {
      if (sessionStorage.getItem("userkey") == null) {
        this.$message({
          message: "请先登录，更好的能为您服务！",
          type: "warning",
        });
        this.$emit("sigin", true);
      } else {
        var keys = generatekey();
        var sign = encryptDes(
          JSON.stringify({
            id: this.trandata[data].tii_reg_id,
            type: this.trandata[data].tii_internation_type,
          }),
          keys
        );

        const { href } = this.$router.resolve({
          path: `/details/${sign}.html`,
        });
        window.open(href, "_blank");
      }
    },
    chata(data) {
      if (sessionStorage.getItem("userkey") == null) {
        this.$message({
          message: "请先登录，更好的能为您服务！",
          type: "warning",
        });
        this.$emit("sigin", true);
      } else {
        this.$emit("chata", data);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  height: 11.25rem /* 180px -> 11.25rem */;
  width: 100%;
  border-bottom: 0.0625rem /* 1px -> 0.0625rem */ solid #efefef;
  padding-left: 1.4375rem /* 23px -> 1.4375rem */;
  padding-right: 1.4375rem /* 23px -> 1.4375rem */;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .left {
    width: 6.36875rem /* 101.9px -> 6.36875rem */;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.875rem /* 14px -> 0.875rem */;
    color: #333333;
    cursor: pointer;
    .picture {
      width: 6.36875rem /* 101.9px -> 6.36875rem */;
      height: 6.36875rem /* 101.9px -> 6.36875rem */;
      border: 0.0625rem /* 1px -> 0.0625rem */ solid #cccccc;
      border-radius: 0.625rem /* 10px -> 0.625rem */;
      padding: 0.0625rem /* 1px -> 0.0625rem */;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .imger {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .right {
    width: 62.5rem /* 1000px -> 62.5rem */;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .lineone {
      display: flex;
      justify-content: space-between;
    }
    .linetow {
      display: flex;
    }
    .boxtitle {
      font-size: 1.25rem /* 20px -> 1.25rem */;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
    }
    .word01 {
      font-size: 0.875rem /* 14px -> 0.875rem */;
      color: #666666;
      margin-right: 4.375rem /* 70px -> 4.375rem */;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .hove {
      color: #868686;
    }
    .hove:hover {
      color: #4d89f1;
    }
    .chata {
      width: 1.25rem /* 20px -> 1.25rem */;
      height: 1.25rem /* 20px -> 1.25rem */;
      margin-left: 0.8125rem /* 13px -> 0.8125rem */;
    }
    .word02 {
      font-size: 0.875rem /* 14px -> 0.875rem */;
      color: #f89941;
      margin-left: 0.3125rem /* 5px -> 0.3125rem */;
    }
  }
}
</style>