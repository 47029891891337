import { render, staticRenderFns } from "./box.vue?vue&type=template&id=3bc8486f&scoped=true&"
import script from "./box.vue?vue&type=script&lang=js&"
export * from "./box.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./box.vue?vue&type=style&index=0&id=3bc8486f&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3bc8486f",
  "426617d8"
  
)

export default component.exports